<script setup lang="ts">
  import type {AsyncData, NuxtError} from '#app';
  import type {ICatMan} from '~/types';
  import type {ICategoriesWidget} from '~/utils/services/api';

  const props = defineProps<{
      widget: ICategoriesWidget
  }>();

  const numberToDisplay = 6;

  const {data} = await useFlatCategories(props.widget.categories, numberToDisplay) as AsyncData<ICatMan[], NuxtError<unknown> | null>;

</script>

<template>
    <CpSectionHome v-if="data" :items="data" :number-to-display="numberToDisplay" />
</template>
