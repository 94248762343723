<script setup lang="ts">
    import {computed} from 'vue';
    import {useI18n} from 'vue-i18n';
    import useCpBem from '~/composables/useCpBem';
    import CpCardCategory from '~/components/cp/CpCardCategory/CpCardCategory.vue';
    import CpButton from '~/components/cp/CpButton/CpButton.vue';
    import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
    import type {ICatMan} from '~/types';

    const props = withDefaults(defineProps<{
        numberToDisplay?: number
        items: Array<ICatMan>
    }>(), {
        numberToDisplay: 6,
    });

    defineEmits(['go-to-categories']);

    const subList = computed(() => props.items.slice(0, props.numberToDisplay));

    const {b, e} = useCpBem('cp-section-home');
    const {t} = useI18n();
</script>

<template>
    <div :class="b">
        <CpSectionHeader :class="e`width`" :title="t('title')" type="h2" show-top-border />
        <div :class="e`row`" data-testid="home-categories-cards">
            <CpCardCategory
                v-for="item in subList"
                :id="item.id"
                :key="item.id"
                :class="e`card`"
                :title="item.title"
                :image="item.image ?? item.image"
                size="large"
                :link="item.link"
                half-size-width
            />
        </div>
        <div :class="e`button-container`">
            <CpNuxtLink page-name="categories">
                <CpButton
                    label="Ver todas las categorías"
                    kind="label"
                    hollow
                    full-width
                    data-testid="home-categories-button"
                />
            </CpNuxtLink>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .cp-section-home {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__row {
            width: 100%;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            gap: $cp-gutter;
            padding: $cp-gutter $cp-gutter 0;
        }
        &__text {
            width: 100%;
            padding: $cp-gutter;
            border-width: 1px 0;
            border-style: solid;
            border-color: #EEEEEE;
            margin-top: $cp-gutter;
        }
        &__button-container {
            width: 100%;
            padding: $cp-gutter;
        }
        &__card {

        }
        &__width {
            width: 100%;
        }
    }
</style>
<i18n locale="es-US" lang="yaml">
    title: "Categorías destacadas"
</i18n>
